import React from "react"
import LazyLoad from "react-lazyload"

const Styles = {
  root: { margin: 0, padding: 0 },
}

const Image = ({ src, alt }) => {
  return (
    <LazyLoad height={600}>
      <img style={Styles.root} src={src} alt={alt} />
    </LazyLoad>
  )
}

export default Image
