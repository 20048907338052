import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/Image"

const images = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
].map(index => {
  return {
    src: require(`../images/lonely-coast/${index}.jpg`),
    title: "Untitled, 2011",
  }
})

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div
      style={{
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {images.map((image, index) => {
        return (
          <div key={image.src} style={{ display: "inline" }}>
            <Image src={image.src} alt={image.title} />
            <div style={{ fontSize: 14, marginBottom: 25 }}>
              #{index + 1} ⁠— {image.title}
            </div>
          </div>
        )
      })}
    </div>
  </Layout>
)

export default IndexPage
